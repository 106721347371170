import React from "react";

import { ThemeContext } from "../../context/ThemeContext"

const ThemeToggle = () => {
  const { theme, setUserThemeOverride } = React.useContext(ThemeContext)

  return (
    <div className="-mx-8">
      <button
        onClick={e => setUserThemeOverride(theme === "dark" ? "light" : "dark")}
        className={`
        text-green-700 dark:text-white
          py-6 px-8 rounded-3xl pointer-cursor
          hover:bg-green-500 hover:bg-opacity-5
          focus:outline-none focus:ring ring-green-500 ring-opacity-20
        `}
      >
        {theme === "dark" ? "Light" : "Dark"} mode
      </button>
    </div>
  )
}

export default ThemeToggle;
