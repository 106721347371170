import React from "react";

const getInitialTheme = () => {
  if (typeof window !== "undefined" && window.localStorage) {
    const storedPrefs = window.localStorage.getItem("theme")
    if (typeof storedPrefs === "string") {
      return storedPrefs
    }

    const userMedia = window.matchMedia("(prefers-color-scheme: dark)")
    if (userMedia.matches) {
      return "dark"
    }
  }

  return "light"
}

export const ThemeContext = React.createContext()

export const ThemeProvider = ({ initialTheme, children }) => {
  const [theme, setTheme] = React.useState(getInitialTheme)
  const [userThemeOverride, setUserThemeOverride] = React.useState()

  const rawSetTheme = theme => {
    const root = window.document.documentElement
    const isDark = theme === "dark"

    root.classList.remove(isDark ? "light" : "dark")
    root.classList.add(theme)
  }

  const rawSetUserThemeOverride = userThemeOverride => {
    if (!userThemeOverride) { return }

    setTheme(userThemeOverride)
    localStorage.setItem("theme", userThemeOverride)
  }

  if (initialTheme) {
    rawSetTheme(initialTheme)
  }

  // Update the theme display if theme state changes
  React.useEffect(
    () => {
      rawSetTheme(theme)
    },
    [theme]
  )

  // Update the theme state if user adjusts the theme control
  React.useEffect(
    () => {
      rawSetUserThemeOverride(userThemeOverride)
    },
    [userThemeOverride]
  )

  return (
    <ThemeContext.Provider value={{ theme, setUserThemeOverride }}>
      {children}
    </ThemeContext.Provider>
  )
}
