import React from "react";
import PropTypes from "prop-types";
import Helmet from "react-helmet";
import { Link, StaticQuery, graphql } from "gatsby";

import { Navigation } from ".";
import { ThemeToggle } from "."
import { ThemeProvider } from "../../context/ThemeContext"
import config from "../../utils/siteConfig";

// Styles
import "../../styles/global.css";
import "../../styles/app.css";

/**
 * Main layout component
 *
 * The Layout component wraps around each page and template.
 * It also provides the header, footer as well as the main
 * styles, and meta data for each page.
 *
 */
const DefaultLayout = ({ data, children, bodyClass, isHome }) => {
  const site = data.allGhostSettings.edges[0].node;
  const twitterUrl = site.twitter
    ? `https://twitter.com/${site.twitter.replace(/^@/, ``)}`
    : null;

  return (
    <ThemeProvider>
      <Helmet>
        <html lang={site.lang} />
        <style type="text/css">{`${site.codeinjection_styles}`}</style>
        <body className={bodyClass} />
      </Helmet>

      <div className="viewport light">
        <div className="viewport-top">
          {/* The main header section on top of the screen */}
          <header>
            <div className="container">
              <nav className="flex mt-6 items-center justify-between">
                {/* The navigation items as setup in Ghost */}
                <Navigation data={site.navigation}/>
                <ThemeToggle />
              </nav>
            </div>
          </header>

          <main className="site-main">
            {/* All the main content gets inserted here, index.js, post.js */}
            {children}
          </main>
        </div>

        <div className="viewport-bottom">
          {/* The footer at the very bottom of the screen */}
          <footer className="site-foot">
            <div className="site-foot-nav container">
              <div className="site-foot-nav-left">
                <Link to="/">{site.title}</Link> © 2022
              </div>
              <div className="site-foot-nav-right">
                {site.twitter && (
                  <a
                    href={twitterUrl}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Twitter
                  </a>
                )}
                <a
                  href={`https://feedly.com/i/subscription/feed/${config.siteUrl}/rss/`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  RSS Feed
                </a>
              </div>
            </div>
          </footer>
        </div>
      </div>
    </ThemeProvider>
  );
};

DefaultLayout.propTypes = {
  children: PropTypes.node.isRequired,
  bodyClass: PropTypes.string,
  isHome: PropTypes.bool,
  data: PropTypes.shape({
    file: PropTypes.object,
    allGhostSettings: PropTypes.object.isRequired
  }).isRequired
};

const DefaultLayoutSettingsQuery = props => (
  <StaticQuery
    query={graphql`
      query GhostSettings {
        allGhostSettings {
          edges {
            node {
              ...GhostSettingsFields
            }
          }
        }
      }
    `}
    render={data => <DefaultLayout data={data} {...props} />}
  />
);

export default DefaultLayoutSettingsQuery;
